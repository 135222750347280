<template>
  <main class="orgs-wrapper">
    <section class="child-route">
      <router-view />
    </section>
  </main>
</template>

<script>
export default {};
</script>

<style scoped>
.orgs-wrapper {
  display: flex;
  justify-content: center;
  height: 100% !important;
  width: 100%;
  gap: 16px;
}

.child-route {
  /* width: calc(100% - var(--orgs-nav-width) - var(--orgs-wrapper-gap)); */
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  /* justify-content: flex-start; */
}
</style>